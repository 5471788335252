import React from 'react';
import { Box, Typography } from '@mui/material';
import { StorageFile } from 'store/storage/interfaces';
import ImageTwoToneIcon from '@mui/icons-material/ImageTwoTone';

interface FileItemProps {
  item: StorageFile;
  onClick: () => void;
}

function FileItem({ item, onClick }: FileItemProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: 74,
        minHeight: 74,
      }}
    >
      <ImageTwoToneIcon
        fontSize="large"
        sx={{
          cursor: 'pointer',
          transition: 'transform 0.2s ease-in-out',
          '&:hover': {
            transform: 'scale(1.2)',
          },
        }}
        onClick={onClick}
      />
      <Typography
        variant="caption"
        sx={{
          textAlign: 'center',
          display: '-webkit-box',
          WebkitLineClamp: 2,
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
        }}
      >
        {item.id}
      </Typography>
    </Box>
  );
}

export default FileItem;
