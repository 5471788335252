import React, { forwardRef } from 'react';
import { Controller, useForm, UseFormSetError } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, TextField } from '@mui/material';
import { t } from 'i18next';
import { InferType } from 'yup';
import { getErrorText } from 'helpers/getErrorText';
import storageDirectoryFormSchema from 'components/Forms/StorageDirectoryForm/utils/StorageDirectoryFormSchema';
import defaultValues from 'components/Forms/StorageDirectoryForm/utils/defaultValues';

export type FormData = InferType<typeof storageDirectoryFormSchema>;

interface StorageDirectoryFormProps {
  onSubmit: (data: FormData, setError: UseFormSetError<FormData>) => void;
}

const StorageDirectoryForm = forwardRef<
  HTMLFormElement,
  StorageDirectoryFormProps
>(({ onSubmit }, ref) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<FormData>({
    resolver: yupResolver(storageDirectoryFormSchema),
    mode: 'onBlur',
    defaultValues,
  });

  return (
    <Box
      component="form"
      display="flex"
      width="100%"
      onSubmit={handleSubmit((data) => onSubmit(data, setError))}
      ref={ref}
    >
      <Controller
        name="name"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            type="text"
            label={t('directory_name')}
            variant="outlined"
            error={!!errors.name}
            helperText={getErrorText(errors.name)}
          />
        )}
      />
    </Box>
  );
});

StorageDirectoryForm.displayName = 'StorageDirectoryForm';

export default StorageDirectoryForm;
