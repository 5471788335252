import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { t } from 'i18next';
import { StorageFile } from 'store/storage/interfaces';
import { useAppDispatch } from 'store/hooks';
import { fetchImagePreview } from 'store/storage/thunks';
import useDisplayNotification from 'hooks/useDisplayNotification';

interface ImagePreviewDialogProps {
  isDialogOpen: boolean;
  handleDialogClose: () => void;
  image: StorageFile | null;
}

function ImagePreviewDialog({
  isDialogOpen,
  handleDialogClose,
  image,
}: ImagePreviewDialogProps) {
  const dispatch = useAppDispatch();
  const { displayErrorNotification } = useDisplayNotification();

  const [previewUrl, setPreviewUrl] = useState<string | null>(null);

  useEffect(() => {
    const loadImage = async () => {
      if (!image) {
        setPreviewUrl(null);
        return;
      }

      try {
        const url = await dispatch(
          fetchImagePreview({ fileId: image.id }),
        ).unwrap();

        setPreviewUrl(url);
      } catch (error) {
        displayErrorNotification(error);
      }
    };

    loadImage();

    return () => {
      if (!previewUrl) {
        return;
      }

      URL.revokeObjectURL(previewUrl);
    };
  }, [dispatch, image, previewUrl, displayErrorNotification]);

  return (
    <Dialog
      open={isDialogOpen}
      onClose={handleDialogClose}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle sx={{ pb: 1 }} color="primary">
        {t('image_preview')}
      </DialogTitle>
      <DialogContent sx={{ pb: 1 }}>
        <Box
          sx={{
            pt: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            component="img"
            src={previewUrl ?? ''}
            alt={image?.id}
            loading="lazy"
            sx={{
              maxWidth: '100%',
              maxHeight: '100%',
              objectFit: 'contain',
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ px: 3, pb: 2 }}>
        <Button autoFocus onClick={handleDialogClose}>
          {t('close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ImagePreviewDialog;
