import React, { useState } from 'react';
import { Button } from '@mui/material';
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { t } from 'i18next';
import RoundedBox from 'common/RoundedBox/RoundedBox';
import StorageDirectoryDialog from 'components/Dialogs/StorageDirectoryDialog/StorageDirectoryDialog';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { currentStorageDirectory } from 'store/storage/selectors';
import { uploadFile } from 'store/storage/thunks';
import { StorageRelationType } from 'store/storage/interfaces';
import useDisplayNotification from 'hooks/useDisplayNotification';

function ControlsSection() {
  const dispatch = useAppDispatch();
  const storageDirectory = useAppSelector(currentStorageDirectory);
  const { displaySuccessNotification, displayErrorNotification } = useDisplayNotification();

  const [isStorageDirectoryDialogOpen, setIsStorageDirectoryDialogOpen] =
    useState(false);

  const handleUploadImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    if (!event.target.files || !storageDirectory) {
      return;
    }

    const formData = new FormData();
    formData.append('file', event.target.files[0]);
    formData.append('relationId', storageDirectory.id);
    formData.append('relationName', StorageRelationType.storageDirectory);

    try {
      await dispatch(uploadFile(formData)).unwrap();
      
      displaySuccessNotification(t('success'));
    } catch (error) {
      displayErrorNotification(error);
    }
  };

  return (
    <>
      <RoundedBox
        sx={{
          p: 1,
          width: '100%',
          display: 'flex',
          flexWrap: 'wrap',
          gap: 1,
        }}
      >
        <Button
          variant="outlined"
          startIcon={<CreateNewFolderOutlinedIcon />}
          onClick={() => setIsStorageDirectoryDialogOpen(true)}
          sx={{ width: { xs: '100%', sm: 'initial' } }}
        >
          {t('create_directory')}
        </Button>
        <Button
            component="label"
            role={undefined}
            variant="outlined"
            tabIndex={-1}
            startIcon={<CloudUploadOutlinedIcon />}
            sx={{
              width: { xs: '100%', sm: 'initial' },
              input: {
                display: 'none',
                visibility: 'hidden',
              },
            }}
          >
            {t('upload_image')}
            <input
              type="file"
              onChange={handleUploadImage}
              accept="image/*"
              value=""
            />
          </Button>
      </RoundedBox>
      <StorageDirectoryDialog
        isDialogOpen={isStorageDirectoryDialogOpen}
        handleDialogClose={() => setIsStorageDirectoryDialogOpen(false)}
      />
    </>
  );
}

export default ControlsSection;
