import { RootState } from 'store';

export const isRequestPending = (state: RootState) =>
  state.storage.isRequestPending;

export const storageDirectories = (state: RootState) =>
  state.storage.storageDirectories;

export const currentStorageDirectory = (state: RootState) =>
  state.storage.currentStorageDirectory;

export const currentStorageDirectoryFiles = (state: RootState) =>
  state.storage.currentStorageDirectoryFiles;
