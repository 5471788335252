import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import defaultState from 'store/articles/defaultState';
import {
  createArticle,
  editArticle,
  fetchArticleById,
  fetchArticles,
} from 'store/articles/thunks';
import { Article } from 'store/articles/interfaces';

export const articles = createSlice({
  name: 'articles',
  initialState: defaultState,
  reducers: {
    setCurrentArticleById: (
      draft,
      action: PayloadAction<{ articleId: string | null }>,
    ) => {
      const chosenArticle = draft.articles?.find(
        (article) => article.id === action.payload.articleId,
      );

      draft.currentArticle = chosenArticle || null;

      if (!chosenArticle?.id) {
        return;
      }

      localStorage.setItem('article', chosenArticle.id);
    },

    setArticles: (draft, action: PayloadAction<Article[] | null>) => {
      draft.articles = action.payload;
    },
    resetArticlesState: (draft) => {
      draft.articles = defaultState.articles;
      draft.currentArticle = defaultState.currentArticle;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchArticles.pending, (draft) => {
      draft.isRequestPending = true;
    });
    builder.addCase(fetchArticles.fulfilled, (draft, action) => {
      const { 'hydra:member': articles } = action.payload;
      draft.articles = articles;

      const articleId = localStorage.getItem('article');

      const chosenArticle = draft.articles.find(
        (article) => article.id === articleId,
      );

      draft.currentArticle = chosenArticle || null;

      draft.isRequestPending = false;
    });
    builder.addCase(fetchArticles.rejected, (draft) => {
      draft.isRequestPending = false;
    });

    builder.addCase(createArticle.pending, (draft) => {
      draft.isRequestPending = true;
    });
    builder.addCase(createArticle.fulfilled, (draft, action) => {
      if (!draft?.articles) {
        return;
      }
      draft.articles.push(action.payload);
      draft.isRequestPending = false;
    });
    builder.addCase(createArticle.rejected, (draft) => {
      draft.isRequestPending = false;
    });

    builder.addCase(editArticle.pending, (draft) => {
      draft.isRequestPending = true;
    });
    builder.addCase(editArticle.fulfilled, (draft, action) => {
      if (!draft?.articles) {
        return;
      }
      const index = draft.articles.findIndex(
        (article) => article.id === action.payload.id,
      );
      if (index >= 0) {
        draft.articles[index] = action.payload;
      }
      draft.isRequestPending = false;
    });
    builder.addCase(editArticle.rejected, (draft) => {
      draft.isRequestPending = false;
    });

    builder.addCase(fetchArticleById.pending, (draft) => {
      draft.isRequestPending = true;
    });
    builder.addCase(fetchArticleById.rejected, (draft) => {
      draft.isRequestPending = false;
    });
    builder.addCase(fetchArticleById.fulfilled, (draft) => {
      draft.isRequestPending = false;
    });
  },
});

export const { setCurrentArticleById, setArticles, resetArticlesState } =
  articles.actions;

export default articles.reducer;
