import React, { useEffect, useState } from 'react';
import Layout from 'components/Layout/Layout';
import RoundedBox from 'common/RoundedBox/RoundedBox';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import Grid from '@mui/material/Grid2';
import { currentProject } from 'store/projects/selectors';
import {
  fetchStorageDirectories,
  fetchStorageFiles,
} from 'store/storage/thunks';
import ControlsSection from 'pages/Files/ControlsSection/ControlsSection';
import { t } from 'i18next';
import {
  currentStorageDirectory,
  currentStorageDirectoryFiles,
  storageDirectories,
} from 'store/storage/selectors';
import { Box, IconButton, Typography } from '@mui/material';
import {
  StorageDirectory,
  StorageFile,
  StorageRelationType,
} from 'store/storage/interfaces';
import DirectoryItem from 'pages/Files/DirectoryItem/DirectoryItem';
import FileItem from 'pages/Files/FileItem/FileItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { setCurrentStorageDirectoryById } from 'store/storage/storage';
import ImagePreviewDialog from 'components/Dialogs/ImagePreviewDialog/ImagePreviewDialog';

function Files() {
  const project = useAppSelector(currentProject);
  const allStorageDirectories = useAppSelector(storageDirectories);
  const storageDirectory = useAppSelector(currentStorageDirectory);
  const storageDirectoryFiles = useAppSelector(currentStorageDirectoryFiles);

  const [currentStorageDirectoryContent, setCurrentStorageDirectoryContent] =
    useState<StorageDirectory[]>([]);

  const [isImagePreviewDialogOpen, setIsImagePreviewDialogOpen] =
    useState(false);

  const [selectedFile, setSelectedFile] = useState<StorageFile | null>(null);

  const dispatch = useAppDispatch();

  const handleBackToParentDirectory = () => {
    if (!storageDirectory?.parent) {
      return;
    }

    dispatch(
      setCurrentStorageDirectoryById({
        storageDirectoryId: storageDirectory.parent.id,
      }),
    );
  };

  useEffect(() => {
    if (!project?.id) {
      return;
    }

    dispatch(fetchStorageDirectories({ project: project.id }));
  }, [dispatch, project]);

  useEffect(() => {
    if (!storageDirectory) {
      setCurrentStorageDirectoryContent([]);
      return;
    }

    const currentContent =
      allStorageDirectories?.filter(
        (directory) => directory.parent?.id === storageDirectory.id,
      ) || [];

    setCurrentStorageDirectoryContent(currentContent);
  }, [allStorageDirectories, storageDirectory]);

  useEffect(() => {
    if (!storageDirectory) {
      return;
    }

    dispatch(
      fetchStorageFiles({
        relationId: storageDirectory.id,
        relationName: StorageRelationType.storageDirectory,
      }),
    );
  }, [dispatch, storageDirectory]);

  const handleFileItemClick = (file: StorageFile) => {
    setIsImagePreviewDialogOpen(true);
    setSelectedFile(file);
  };

  const handleImagePreviewDialogClose = () => {
    setIsImagePreviewDialogOpen(false);
    setSelectedFile(null);
  };

  return (
    <>
      <Layout>
        <Grid container direction="column" spacing={1} sx={{ height: '100%' }}>
          <Grid size={12}>
            <ControlsSection />
          </Grid>
          <Grid size={12} sx={{ display: 'flex', flexGrow: 1 }}>
            <RoundedBox sx={{ p: 3, width: '100%' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', pb: 3 }}>
                {storageDirectory?.parent && (
                  <IconButton
                    onClick={handleBackToParentDirectory}
                    sx={{ mr: 1, width: 'unset', height: 'unset', p: 0 }}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                )}
                <Typography color="text.secondary">
                  {`${t('current_directory')}: ${storageDirectory?.name || ''}`}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                {!currentStorageDirectoryContent?.length &&
                !storageDirectoryFiles?.length ? (
                  <Typography variant="body2" color="text.secondary">
                    {t('no_content')}
                  </Typography>
                ) : (
                  <>
                    {currentStorageDirectoryContent.map((directory) => (
                      <DirectoryItem key={directory.id} directory={directory} />
                    ))}
                    {storageDirectoryFiles?.map((file) => (
                      <FileItem
                        key={file.id}
                        item={file}
                        onClick={() => handleFileItemClick(file)}
                      />
                    ))}
                  </>
                )}
              </Box>
            </RoundedBox>
          </Grid>
        </Grid>
      </Layout>
      <ImagePreviewDialog
        isDialogOpen={isImagePreviewDialogOpen}
        handleDialogClose={handleImagePreviewDialogClose}
        image={selectedFile}
      />
    </>
  );
}

export default Files;
