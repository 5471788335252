import React from 'react';
import { Box, Typography } from '@mui/material';
import FolderTwoToneIcon from '@mui/icons-material/FolderTwoTone';
import { StorageDirectory } from 'store/storage/interfaces';
import { useAppDispatch } from 'store/hooks';
import { setCurrentStorageDirectoryById } from 'store/storage/storage';

interface DirectoryItemProps {
  directory: StorageDirectory;
}

function DirectoryItem({ directory }: DirectoryItemProps) {
  const dispatch = useAppDispatch();

  const handleDirectoryClick = () => {
    dispatch(
      setCurrentStorageDirectoryById({ storageDirectoryId: directory.id }),
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: 74,
        minHeight: 74,
      }}
    >
      <FolderTwoToneIcon
        fontSize="large"
        onClick={handleDirectoryClick}
        sx={{
          cursor: 'pointer',
          transition: 'transform 0.2s ease-in-out',
          '&:hover': {
            transform: 'scale(1.2)',
          },
        }}
      />
      <Typography
        variant="caption"
        sx={{
          textAlign: 'center',
          display: '-webkit-box',
          WebkitLineClamp: 2,
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
        }}
      >
        {directory.name}
      </Typography>
    </Box>
  );
}

export default DirectoryItem;
