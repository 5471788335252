import { StorageState } from 'store/storage/interfaces';

const defaultState: StorageState = {
  isRequestPending: false,
  storageDirectories: null,
  currentStorageDirectory: null,
  currentStorageDirectoryFiles: null,
};

export default defaultState;
