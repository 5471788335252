import { combineReducers } from '@reduxjs/toolkit';
import notifications from 'store/notifications/notifiactions';
import authorization from 'store/authorization/authorization';
import organizations from 'store/organizations/organizations';
import projects from 'store/projects/projects';
import articles from 'store/articles/articles';
import branches from 'store/branches/branches';
import storage from 'store/storage/storage';

export default combineReducers({
  notifications,
  authorization,
  organizations,
  projects,
  articles,
  branches,
  storage,
});
