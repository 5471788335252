import React, { Dispatch, SetStateAction } from 'react';
import { Box, Button, Typography, useTheme } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeselectIcon from '@mui/icons-material/Deselect';
import CircleTwoToneIcon from '@mui/icons-material/CircleTwoTone';
import { t } from 'i18next';
import SideSection from 'components/Layout/AppDrawer/SideSection/SideSection';
import Label from 'components/Layout/AppDrawer/SideSection/Label/Label';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { branches, currentBranch } from 'store/branches/selectors';
import ActionButton from 'common/ActionButton/ActionButton';
import getBranchStatusColor from 'pages/Branches/utils/getBranchStatusColor';
import { Branch } from 'store/branches/interfaces';
import { setCurrentBranchById } from 'store/branches/branches';
import { setCurrentArticleById } from 'store/articles/articles';

interface SideSectionContentProps {
  setIsBranchDialogOpen: Dispatch<SetStateAction<boolean>>;
}

function SideSectionContent({
  setIsBranchDialogOpen,
}: SideSectionContentProps) {
  const theme = useTheme();
  const allBranches = useAppSelector(branches);
  const activeBranch = useAppSelector(currentBranch);
  const dispatch = useAppDispatch();

  const setCurrentBranch = (branch: Branch | null) => {
    dispatch(setCurrentBranchById({ branchId: branch?.id || null }));
    dispatch(setCurrentArticleById({ articleId: null }));
  };

  return (
    <SideSection
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        p: 1,
      }}
    >
      <Box>
        {allBranches?.length ? (
          <React.Fragment>
            {allBranches.map((branch) => (
              <ActionButton
                key={branch.id}
                onClick={() => setCurrentBranch(branch)}
                sx={{
                  bgcolor:
                    activeBranch?.id === branch.id
                      ? theme.palette.action.selected
                      : 'transparent',
                }}
              >
                <CircleTwoToneIcon
                  fontSize="small"
                  sx={{ color: getBranchStatusColor(branch) }}
                />
                <Typography
                  variant="body2"
                  color="text.primary"
                  textAlign="left"
                >
                  {branch.name}
                </Typography>
              </ActionButton>
            ))}
          </React.Fragment>
        ) : (
          <Label>{t('no_branches')}</Label>
        )}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Button
          variant="contained"
          size="large"
          color="inherit"
          startIcon={<AddCircleOutlineIcon />}
          onClick={() => setIsBranchDialogOpen(true)}
          sx={{
            m: 1,
          }}
        >
          {t('new_branch')}
        </Button>
        <Button
          variant="contained"
          size="large"
          color="inherit"
          startIcon={<DeselectIcon />}
          onClick={() => setCurrentBranch(null)}
          sx={{
            m: 1,
          }}
        >
          {t('deselect_branch')}
        </Button>
      </Box>
    </SideSection>
  );
}

export default SideSectionContent;
