import React from 'react';
import Layout from 'components/Layout/Layout';
import RoundedBox from 'common/RoundedBox/RoundedBox';
import { Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { exportProject } from 'store/projects/thunks';
import useDisplayNotification from 'hooks/useDisplayNotification';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { currentProject, isRequestPending } from 'store/projects/selectors';
import { t } from 'i18next';

function Settings() {
  const project = useAppSelector(currentProject);
  const requestPending = useAppSelector(isRequestPending);

  const dispatch = useAppDispatch();
  const { displayErrorNotification } = useDisplayNotification();

  const handleExportProjectClick = async () => {
    if (!project) {
      return;
    }

    try {
      await dispatch(exportProject({ id: project.id })).unwrap();
    } catch (error) {
      displayErrorNotification(error);
    }
  };

  return (
    <Layout>
      <RoundedBox sx={{ p: 3, height: '100%' }}>
        <Button
          onClick={handleExportProjectClick}
          disabled={requestPending}
          variant="outlined"
          endIcon={<FileDownloadIcon />}
        >
          {t('download_project_as_pdf')}
        </Button>
      </RoundedBox>
    </Layout>
  );
}

export default Settings;
